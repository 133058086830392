export const RouteLink = {
  Login_Uri: "/",
  Page_Uri: "/page",
  Admin_Uri: "/user/admin",
  AdminHome_Uri: "/page/home",
  Dashboard_Uri: "/user/dashboard",
  RestPassword_Uri: "/user/resetpassword",
  Users_Uri: "/user/admin/users",
  CreateUser_Uri: "/user/admin/createuser",
  UpdateUser_Uri: "/user/admin/updateuser",

  CreatePartner_Uri: "/user/admin/createpartner",
  UpdatePartner_Uri: "/user/admin/updatepartner",
  Partners_Uri: "/user/admin/partners",
  Category_Uri: "/user/admin/category/list",
  Catagory_Create_Uri: "/user/admin/category/create",
  Catagory_Update_Uri: "/user/admin/category/upadate",
  Group_Uri: "/user/admin/group/list",
  Group_Create_Uri: "/user/admin/group/create",
  Group_Update_Uri: "/user/admin/group/update",
  Physician_Uri: "/user/admin/physician/list",
  Physician_Create_Uri: "/user/admin/physician/create",
  Physician_Update_Uri: "/user/admin/physician/update",
  Clinic_Uri: "/user/admin/clinic/list",
  Clinic_Create_Uri: "/user/admin/clinic/create",
  Clinic_Upadte_Uri: "/user/admin/clinic/update",
  Entity_Uri: "/user/admin/entity/list",
  Entity_Create_Uri: "/user/admin/entity/create",
  Entity_Upadte_Uri: "/user/admin/entity/update",
  SubCategory_Uri: "/user/admin/subcategory/list",
  SubCatagory_Create_Uri: "/user/admin/subcategory/create",
  SubCatagory_Update_Uri: "/user/admin/subcategory/update",
  Requirement_Uri: "/user/admin/Requirement/list",
  Requirement_Create_Uri: "/user/admin/Requirement/create",
  Requirement_Update_Uri: "/user/admin/Requirement/upadate",
  RequirementSet_Uri: "/user/admin/RequirementSet/list",
  RequirementSet_Create_Uri: "/user/admin/RequirementSet/create",
  RequirementSet_Update_Uri: "/user/admin/RequirementSet/upadate",
  PublicFile_Uri: "/user/admin/PublicFile/list",
  PublicFile_Create_Uri: "/user/admin/PublicFile/create",
  PublicFile_Update_Uri: "/user/admin/PublicFile/upadate",
  CCM_Uri: "/user/admin/ccm/list",
  CCM_Create_Uri: "/user/admin/ccm/create",
  CCM_Update_Uri: "/user/admin/ccm/update",

  Meeting_Uri: "/user/admin/meeting/list",
  Meeting_Create_Uri: "/user/admin/meeting/create",
  Meeting_Update_Uri: "/user/admin/meeting/update",

  //financial
  Financial_Uri: "/user/financial",
  ReportingEntities_Uri: "/user/financial/ReportingEntities",
  ComponentDetails_Uri: "/user/financial/ComponentDetails",
  FinancialSummary_Uri: "/user/financial/FinancialSummary",
  FinancialDetails_Uri: "/user/financial/FinancialDetails",
  TINComparisons_Uri: "/user/financial/TINComparisons",
  TINSummary_Uri: "/user/financial/TINSummary",
  QPPMismatch_Uri: "/user/financial/QPPMismatch",
  ProvidersWithoutAC_Uri: "/user/financial/ProvidersWithoutAC",
  QPPDataConfirmation_Uri: "/user/financial/QPPDataConfirmation",
  ResourcesNew_Uri: "/user/financial/ResourcesNew",
  FinancialPeerReview_Uri: "/user/financial/FinancialPeerReview",
  MIPSScoreCard_Uri: "/user/financial/MIPSScoreCard",
  ImportQRDAFiles_Uri: "/user/financial/ImportQRDAFiles",
  AssumptionsTarget: "/user/financial/Assumptions1",
  CCM: "/user/ccm/CCM",
  CCMGapAnalysis: "/user/ccm/CCMGapAnalysis",
  CCMGroupAnalysis: "/user/ccm/ccm-group-analysis",

  //Action Item
  ActionItemList: "/user/action-item/List",
  CreateActionItem: "/user/action-item/create",
  updateActionItem: "/user/action-item/update",
  calendar: "/user/action-item/calendar",

  //Utilities
  Gui_Uri: "/user/utilities/gui/list",
  Gui_Create_Uri: "/user/utilities/gui/create",
  Gui_Update_Uri: "/user/utilities/gui/update",

  CSV_Create_Uri: "/user/utilities/csvformate/create",
  CSV_Uri: "/user/utilities/csvformate",
  Formula_Uri: "/user/utilities/formula",
  Formula_List_Uri: "/user/utilities/formula-list",

  //benchmark

  Benchmark_Uri: "/user/utilities/benchmark",
  Benchmark_List_Uri: "/user/utilities/benchmark-list",
  Benchmark_view_Single: "/user/utilities/view-benchmark",

  // Copy_Tin: '/user/utilities/copyTin',
  Physician_Income: "/user/utilities/physicianIncome",
  Physician_Income_Create: "/user/utilities/physicianIncome/create",
  Physician_Income_Update: "/user/utilities/physicianIncome/update",

  CCM_Enable: "/user/utilities/ccm-enable",
  Group_Location_List: "/user/utilities/group-location-list",
  Group_Location: "/user/utilities/group-location",

  //Quality
  CSV_Upload_uri: "/user/quality/csv-upload",
  CSV_Upload_List: "/user/quality/upload-list",
  Measure_Log_List: "/user/quality/measure-log",
  Quaity_PhysicianScore: "/user/quality/physicianscore",
  Quaity_QualityNotes: "/user/quality/qualitynotes",
  Create_QualityNotes: "/user/quality/createqualitynotes",

  //Quality Home
  Quality_Home_Uri: "/user/quality/quality-home",
  //CQM
  CQM_Selector_Uri: "/user/quality/cqm-selector",
  EC_Comparision_Uri: "/user/quality/EC-comparision",
  Group_CQM_Details_Uri: "/user/quality/cqm-details",
  Group_CQM_Summary: "/user/quality/cqm-summary",
  EC_Submission_status_Uri: "/user/quality/EC-submission-status",

  Tin_CQM_Details_Uri: "/user/quality/tin-cqm",
  Measure_CQM_Details_Uri: "/user/quality/measure-cqm",
  Group_CQM_Details: "/user/quality/group-cqm",
  //CPIA
  CPIA_Uri: "/user/cpia",
  IA_Uri: "/user/cpia/iaform",
  CPIAList_Uri: "/user/cpia/cpialist",
  //Submission
  Submission_Uri: "/user/submission",
  SubmissionForm_Uri: "/user/submission/submissionform",
  SubmissionList_Uri: "/user/submission/submissionlist",
  //PI
  Pi_Uri: "/user/pi",
  Piform_Uri: "/user/pi/piform",
  PiList_Uri: "/user/pi/pilist",

  //
  Encounter_Upload_List : "/user/registryfileupload"
  
 
  

};
